<template>
	<section class="grid grid-cols-1 md:grid-cols-2 gap-2">
		<div
			class="w-full pb-0 md:pb-6 lg:pb-24 flex items-end md:justify-start justify-center order-2 md:order-1"
		>
			<div
				class="text-center
                  md:text-left"
			>
				<h2 class="text-red font-semibold pt-4 ">Who we are</h2>
				<p class="pt-4 ">A team of expert software developers.</p>
			</div>
		</div>

		<div
			class="
                  w-full
                  order-1
                  md:order-2
                  md:pb-16
                  xl:pb-20
                  "
		>
			<section class="w-full flex w-full justify-center px-5">
				<img src="@/assets/about-page-who-we-are.png" alt="" />
			</section>
		</div>
	</section>
	<section class="grid grid-cols-1 md:grid-cols-2 gap-2 md:pt-10 pt-24">
		<div
			class="
                  w-full
                  "
		>
			<section
				class="w-full flex w-full md:justify-start justify-center px-5"
			>
				<img src="@/assets/about-page-what-we-do.png" alt="" />
			</section>
		</div>

		<div class="w-full justify-center items-center flex">
			<div
				class="text-center
                  md:text-left"
			>
				<h2 class="text-red font-semibold pt-4">What we do</h2>
				<p class="pt-4">Build cool things for our clients.</p>
			</div>
		</div>
	</section>

	<section class="grid grid-cols-1 md:grid-cols-2 gap-2 md:pt-10 pt-24">
		<div
			class="w-full md:justify-start justify-center items-center flex order-2 md:order-1"
		>
			<div
				class="text-center
                  md:text-left"
			>
				<h2 class="text-red font-semibold pt-4">Our Goal</h2>
				<p class="pt-4 max-w-md">
					To give you access to the full power of technology to help
					your business run smoothly.
				</p>
			</div>
		</div>

		<div
			class="
                  w-full
                  order-1
                  md:order-2
                  "
		>
			<section class="w-full flex w-full justify-center px-5">
				<img
					src="@/assets/about-page-our-goal.png"
					alt="about-page-our-goal - indentations.io"
				/>

			</section>
		</div>
	</section>
</template>
