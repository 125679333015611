<template>
   <Main :showReadMore="false">
      <section class="banner pt-28 md:pt-32 lg:pt-32 2xl:pt-48 px-10">
         <div class="w-full mx-auto max-w-screen-xl pt-6 sm:pt-7 md:pt-0">
            <MainSection />
         </div>
      </section>

      <section class="px-10 -mt-32 md:-mt-56 xl:-mt-80">
         <div class="mx-auto max-w-screen-xl">
            <SectionTwo />
         </div>
      </section>
      <section class="md:pt-52 pt-32 px-6 xs:px-10">
         <div class="mx-auto max-w-screen-xl">
            <SectionThree />
         </div>
      </section>

      <section class="md:pt-52 pt-32 px-6 xs:px-10">
         <div class="mx-auto max-w-screen-xl">
            <SectionFour />
         </div>
      </section>

      <section class="pt-20 px-6 xs:px-10">
         <div class="mx-auto max-w-screen-xl">
            <SectionFive />
         </div>
      </section>

      <div class="pt-20"></div>
   </Main>
</template>
<script>
import Main from "@/components/Main";
import MainSection from "./MainSection";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";

export default {
   components: {
      Main,
      MainSection,
      SectionTwo,
      SectionThree,
      SectionFour,
      SectionFive,
   },
   mounted() {
      window.onload = () => {
         const el = document.getElementById("loader-container");
         el.classList.add("hidden");
      };
   },
};
</script>
