<template>
	<div class="pb-28">
		<div class="w-full text-white pb-5">
			<div
				class="w-full pb-14 md:pt-14 xl:pb-14 flex justify-center md:justify-start"
			>
				<h1 class="whitespace-nowrap leading-none relative">
					<div
						class="
						flex
						relative
						items-center
                  "
					>
						<span class="leading-none">
							About
							<span class="text-red  font-bold"> Us</span>
						</span>

						<div class="absolute">
							<span class="invisible">About Us</span>

							<div
								class="font-bold border-b-6 py-5 my-1   mx-auto md:mx-0 right-0"
								style="width: 39%;"
							></div>
						</div>
					</div>
				</h1>
			</div>
		</div>
	</div>
</template>
