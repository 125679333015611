<template>
	<div class="w-full justify-center flex flex-col text-center">
		<p class="max-w-3xl mx-auto">
			We have many years of experience providing software solutions to
			clients. From websites and dashboards to API's and microservices. We
			have solved software challenges that seemed insurmountable. Tell us
			what you need. We can help!
		</p>

		<div class="pt-16 "><a href="/contact" class="rounded-full tracking-wider w-full max-w-xs md:w-auto py-3 text-center text-white inline-block px-8 text-sm md:text-base uppercase bg-red font-semibold hover:bg-red-700 whitespace-nowrap">Get in touch </a></div>

	</div>
</template>
